import {merge, of, ReplaySubject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {take, debounceTime} from 'rxjs/operators';
import {Location} from '@angular/common';
import {FilterService} from './filter.service';
import {addQueryString} from './api.service';

// If we set multiple filters in one go (ex.: start and end date)
// the update subject will fire two times in a row. To get around this
// we introduce a small debounce time, which will (hopefully) prevent
// the unnecessary emits on the subject
const FILTER_UPDATE_DEBOUNCE = 100;

export class Filter<T = any> {
    handleQueryParams = false;
    private _updates$ = new ReplaySubject<Partial<T>>(1);
    private _emitUpdateObs = true;

    get updates$() {
        return this._updates$.asObservable().pipe(debounceTime(FILTER_UPDATE_DEBOUNCE));
    }

    get params() {
        return this._params;
    }

    set params(x: Partial<T>) {
        this._skipUpdate = true;
        Object.assign(this._params, x);
        this._skipUpdate = false;
        this._update();
    }

    get(key?: string | number) {
        return key ? this.params[key] : this.params;
    }

    set(x: any | string | number, value?: any | string | number, emitUpdateObs = true) {
        this._emitUpdateObs = emitUpdateObs;
        if (typeof x === 'object') {
            this.params = x;
        } else {
            this.params[x] = value;
        }
        this._emitUpdateObs = true;
    }

    emit() {
        this._updates$.next(this.params);
    }

    private _skipUpdate = false;

    private _params: Partial<T> = new Proxy({}, {
        set: <K extends keyof T & string>(obj: T, prop: K, value: T[K]) => {
            const val = this._convertValue(prop, value);
            const prev = obj[prop];

            obj[prop] = val;

            if (!this._skipUpdate && (
                Array.isArray(val) ?
                    prev ?
                        !Array.isArray(prev) || (val as any).some(x => !(prev as any).includes(x)) || (prev as any).some(x => !(val as any).includes(x)) :
                        val.length :
                    prev !== val && ![prev, val].every(x => [null, undefined].includes(x))
            )) {
                this._update();
            }
            return true;
        },
    });

    private _update() {
        FilterService.activeFilter$.next(this.params);
        if (this._emitUpdateObs) this._updates$.next(this.params);
        if (this.router && this.handleQueryParams) {
            setTimeout(() => {
                if (!this.handleQueryParams) return;

                this.router.navigateByUrl(getUrlWithQueryParams(this._getQueryParams()), {replaceUrl: true});
            });
        }
    }

    private _processValue(value: any) {
        if (Array.isArray(value)) {
            const v = value.map(x => this._processValue(x));
            return v.length ? v : null;
        }

        if (value == null) return null;

        if (value === '0') return 0;

        if ([true, 'true'].includes(value)) return true;

        if ([false, 'false'].includes(value)) return false;

        if (value instanceof Date) return value;

        const numVal = +value;

        return numVal && !(typeof value === 'string' && value?.startsWith('0')) ? numVal as any : value;
    }

    private _convertValue<K extends keyof T>(prop: K, val: T[K]): T[K] {
        if (this.arrayParamKeys && this.arrayParamKeys.length && this.arrayParamKeys.includes(prop)) {
            if (val) {
                return Array.isArray(val) ? val : this._processValue(val.toString().split(',') as any as T[K]);
            }
            return null as any as T[K];
        }
        return this._processValue(val);
    }

    private _getQueryParams() {
        if (!this.params) return;

        return Object.keys(this.params).reduce((acc, key) => {
            if (this.arrayParamKeys && this.arrayParamKeys.includes(key as keyof T) && this.params[key] && this.params[key].length) {
                acc[key] = this.params[key];
            } else if (!this.arrayParamKeys || !this.arrayParamKeys.includes(key as keyof T)) {
                acc[key] = this.params[key];
            }

            return acc;
        }, ({} as Partial<T>));
    }

    extend<K extends T>(params: Partial<K> = {}, arrayParamKeys?: (keyof T)[]) {
        const extension = new Filter<K>({...this._params, ...params}, arrayParamKeys);
        const mergedUpdates$ = merge(this.updates$, extension.updates$);

        mergedUpdates$.subscribe(newFilters => {
            Object.keys(newFilters).forEach(key => {
                if (this._params[key] !== undefined && this._params[key] !== newFilters[key]) this._params[key] = newFilters[key];
                if (extension.params[key] !== undefined && extension.params[key] !== newFilters[key]) extension.params[key] = newFilters[key];
            });
        });

        return extension;
    }

    constructor(params?: Partial<T>,
                private arrayParamKeys?: (keyof T)[],
                private location?: Location,
                private router?: Router,
                private activatedRoute?: ActivatedRoute) {
        this.handleQueryParams = !!this.activatedRoute;
        const nullParams: Partial<T> = {};
        if (params) Object.keys(params).forEach(key => nullParams[key] = params[key] ?? null);
        (this.activatedRoute ? this.activatedRoute.queryParams.pipe(take(1)) : of(params || this.params)).subscribe((qps: Partial<T>) => {
            this.params = {...nullParams, ...(qps || {})};
        });
    }
}

export function getUrlWithQueryParams(queryParams: {[key: string]: any}) {
    return addQueryString(window.location.pathname, queryParams);
}
